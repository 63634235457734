@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&family=Playfair+Display:wght@400;500;600;700;800&family=Poppins:wght@300;400;500;600;700;800&display=swap');

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.f-carousel {
    --f-button-width: 38px;
    --f-button-height: 38px;

    --f-button-svg-width: 16px;
    --f-button-svg-height: 16px;
    --f-button-svg-stroke-width: 2.5;

    --f-button-color: rgb(71 85 105);
    --f-button-border-radius: 50%;
    --f-button-shadow: 0 6px 12px -2px rgb(50 50 93 / 25%), 0 3px 7px -3px rgb(0 0
            0 / 30%);

    --f-button-bg: #fff;
    --f-button-hover-bg: #f9f9f9;
    --f-button-active-bg: #f0f0f0;
  }

  .f-carousel {
    --f-carousel-slide-width: 120%;
  }

  *{scroll-behavior: smooth;}

  .menu-vendor-tool-img{width: 40px; height:40px;}
  #chat_container a{color:blue; }

  @keyframes pulse-red {
  0% {
    background-color: rgba(255, 0, 0, 0.1); /* Light red */
  }
  50% {
    background-color: rgba(255, 0, 0, 0.3); /* Slightly darker red */
  }
  100% {
    background-color: rgba(255, 0, 0, 0.1); /* Light red */
  }
}

.pulse-red {
  animation: pulse-red 2s infinite;
}

.w-full{
  width: 100%;
}
@media (min-width: 1280px) {
  .xl\:grid-cols-\[40\%_60\%\] {
    grid-template-columns: 40% 60%;
  }
}
.grid-cols-\[auto_1fr_auto\] {
    grid-template-columns: auto 1fr auto;
}

@media (min-width: 640px) {
  .sm\:w-48 {
      width: 12rem;
  }
}

.customer_ticket_table{
  min-height: 600px;
}